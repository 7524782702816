import { Suspense, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import routes from './routes';
import AppRoute from './components/smart/AppRoute';
import PATH from './routes/paths';
import { me } from './redux/slices/auth';
import TypeChecker from './helpers/classes/TypeChecker';
import { isAuthenticatedSelector } from './redux/slices/selectors';
import AuthLayout from './components/dumb/layouts/AuthLayout';
import LoggedInLayout from './components/dumb/layouts/LoggedInLayout';

function App() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  const element = useRoutes([
    ...routes.map((route) => ({
      path: route.path,
      element: (
        <AppRoute
          component={route.component}
          isProtected={route.isProtected}
          redirectIfAuthenticated={route.redirectIfAuthenticated}
        />
      ),
      ...(TypeChecker.isArray(route.children)
        ? {
            children: route.children.map((child) => ({
              path: child.path,
              element: <child.component />,
            })),
          }
        : {}),
    })),
    { path: '*', element: <Navigate to={PATH.INDEX} replace /> },
  ]);

  useEffect(() => {
    dispatch(me()).then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <div />;
  }

  const Layout = isAuthenticated ? LoggedInLayout : AuthLayout;

  return (
    <Suspense fallback={<div />}>
      <Layout>{element}</Layout>
    </Suspense>
  );
}

export default App;
