import { ReactElement } from 'react';
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';

import dateFormat from 'date-fns/format';
import dateGetHours from 'date-fns/getHours';

import { TooltipProps } from 'recharts/types/component/Tooltip';
import {
  NameType,
  ValueType,
} from 'recharts/src/component/DefaultTooltipContent';
import TypeChecker from 'helpers/classes/TypeChecker';
import * as SC from './styles';

export enum ChartUnit {
  TIMESTAMP = 'timestamp',
  REQUESTS = 'requests',
}

const getFormattedValue = (
  unit: ChartUnit,
  value: any
): { value: string | number; unit: string } => {
  switch (unit) {
    case ChartUnit.TIMESTAMP:
      if (dateGetHours(new Date(value)) === 0) {
        return { value: dateFormat(value, 'LLL d'), unit: '' };
      }
      return { value: dateFormat(value, 'HH:mm'), unit: '' };
    case ChartUnit.REQUESTS:
    default:
      return { value, unit };
  }
};

function StyledTooltip(props: TooltipProps<ValueType, NameType>) {
  const { payload, label } = props;

  if (!payload?.length) return null;

  return (
    <SC.Tooltip>
      <SC.TooltipTitle>
        {dateFormat(label, 'LLL d yyyy  HH:mm')}
      </SC.TooltipTitle>
      {payload.map((item, idx) => {
        const { unit, value } = getFormattedValue(
          ChartUnit.REQUESTS,
          item.value
        );

        return (
          // eslint-disable-next-line react/no-array-index-key
          <SC.TooltipItem key={String(idx)}>
            <SC.TooltipItemValue>{value}</SC.TooltipItemValue>
            <SC.TooltipItemUnit>{unit}</SC.TooltipItemUnit>
          </SC.TooltipItem>
        );
      })}
    </SC.Tooltip>
  );
}

export interface ChartColor {
  id: string;
  stroke: string;
  fill: string;
}

export interface ChartData {
  // describe for values from data array (`value`, `value2`, ...)
  meta: {
    [dataKey: string]: {
      color: ChartColor;
      unit: ChartUnit;
    };
  };
  data: {
    timestamp: number;
    value: number; // value - dataKey from meta
    value2?: number; // value - dataKey from meta
  }[];
}

interface ChartProps {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  colors: ChartColor[];
  data: ChartData;
  select?: ReactElement;
}

export default function Chart({
  title,
  subtitle,
  isLoading,
  colors,
  data,
  select,
}: ChartProps) {
  const theme = useTheme();

  return (
    <SC.Wrapper>
      {title || subtitle ? (
        <SC.Header>
          <SC.Hgroup>
            <SC.Title>{title}</SC.Title>
            {isLoading ? (
              <SC.SubtitlePlaceholder height={16} width={146} />
            ) : (
              <SC.Subtitle>{isLoading ? null : subtitle}</SC.Subtitle>
            )}
          </SC.Hgroup>
          {select || null}
        </SC.Header>
      ) : null}

      <ResponsiveContainer height={404} width="99%">
        {isLoading ? (
          <SC.ChartPlaceholder />
        ) : (
          <SC.StyledAreaChart data={data.data}>
            <defs>
              {colors.map((color) => (
                <linearGradient
                  key={color.id}
                  id={color.id}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="0"
                    stopColor={color.stroke}
                    stopOpacity={0.08}
                  />
                  <stop
                    offset="100%"
                    stopColor={color.stroke}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke={theme.palette.neutrals[100]}
            />
            <XAxis
              dataKey="timestamp"
              tickLine={false}
              stroke={theme.palette.neutrals[300]}
              tickFormatter={(tick) => {
                if (!TypeChecker.isNumber(tick) || tick <= 0) {
                  return '';
                }
                const { value } = getFormattedValue(ChartUnit.TIMESTAMP, tick);
                return `${value}`;
              }}
              y={1}
            />
            <YAxis
              name="requests"
              axisLine={false}
              tickLine={false}
              domain={[0, 'auto']}
              tickFormatter={(tick) => {
                const { value } = getFormattedValue(ChartUnit.REQUESTS, tick);
                return `${value}`;
              }}
              stroke={theme.palette.neutrals[300]}
            />
            <Tooltip
              cursor={{
                stroke: theme.palette.neutrals[100],
                strokeWidth: 1,
              }}
              isAnimationActive={false}
              animationDuration={0}
              content={<StyledTooltip />}
              offset={0}
              allowEscapeViewBox={{ x: true, y: true }}
              position={{ y: -112 }}
            />
            {Object.keys(data.meta).map((dataKey) => {
              const item = data.meta[dataKey];

              return (
                <Area
                  key={dataKey}
                  unit={item.unit}
                  dataKey={dataKey}
                  stroke={item.color.stroke}
                  strokeWidth={2}
                  isAnimationActive={false}
                  activeDot={{
                    fill: item.color.fill,
                    strokeWidth: 0,
                    r: 4,
                  }}
                  fillOpacity={1}
                  fill={`url(#${item.color.id})`}
                />
              );
            })}
          </SC.StyledAreaChart>
        )}
      </ResponsiveContainer>
    </SC.Wrapper>
  );
}
