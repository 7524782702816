import { SVGProps } from 'react';

export default function NoProjectIcon80px(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="121"
      height="102"
      viewBox="0 0 121 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.7639 79.3386C80.4976 79.3386 97.3055 62.4897 97.3055 41.7054C97.3055 20.9212 80.4976 4.07227 59.7639 4.07227C39.0302 4.07227 22.2223 20.9212 22.2223 41.7054C22.2223 62.4897 39.0302 79.3386 59.7639 79.3386Z"
        fill="#F0F4F8"
      />
      <path
        d="M32.1178 17.3237H85.5221C87.6371 17.3237 89.2989 18.9896 89.2989 21.1098V54.9569C89.2989 57.0771 87.6371 58.7429 85.5221 58.7429H32.1178C30.0028 58.7429 28.341 57.0771 28.341 54.9569V21.1098C28.341 18.9896 30.0783 17.3237 32.1178 17.3237Z"
        fill="#D9E2EC"
      />
      <path
        d="M90.3688 39.0407V55.6992C90.3688 57.8194 88.6315 59.6368 86.441 59.6368H31.526C29.411 59.6368 27.5982 57.8951 27.5982 55.6992V37.6021"
        stroke="#1676EE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_17_3590)">
        <path
          d="M85.6323 29.7095H68.3618C66.6811 29.7095 65.0585 30.2894 63.7835 31.3328L59.263 35.0429C57.988 36.0864 56.3654 36.666 54.6847 36.666H35.0381C33.0676 36.666 31.4449 38.2893 31.4449 40.2603C31.4449 40.4342 31.445 40.6081 31.5029 40.782L35.0961 59.9124C35.3858 61.7095 36.8926 63.0428 38.6892 63.0428H80.5322C82.3288 63.0428 83.8356 61.7676 84.1254 59.9705L89.1675 33.8255C89.5153 31.8545 88.1822 30.0573 86.2118 29.7095C86.0379 29.7095 85.8061 29.7095 85.6323 29.7095Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter1_d_17_3590)">
        <path
          d="M93.4656 24.5781H70.9557C68.7652 24.5781 66.6502 25.3356 64.9884 26.6985L59.0965 31.5446C57.4347 32.9075 55.3198 33.6646 53.1292 33.6646H27.5223C24.954 33.6646 22.8391 35.7849 22.8391 38.3594C22.8391 38.5866 22.8391 38.8137 22.9147 39.0409L27.5979 64.0287C27.9756 66.376 29.9395 68.1175 32.2811 68.1175H86.8184C89.16 68.1175 91.1239 66.4518 91.5016 64.1045L98.0734 29.9544C98.5266 27.3799 96.7892 25.0324 94.221 24.5781C93.9944 24.5781 93.6922 24.5781 93.4656 24.5781Z"
          fill="white"
        />
      </g>
      <path
        d="M49.8814 50.474C51.2163 50.474 52.2985 49.389 52.2985 48.0508C52.2985 46.7125 51.2163 45.6279 49.8814 45.6279C48.5464 45.6279 47.4642 46.7125 47.4642 48.0508C47.4642 49.389 48.5464 50.474 49.8814 50.474Z"
        fill="#102A43"
      />
      <path
        d="M68.9169 50.3983C70.2519 50.3983 71.334 49.3137 71.334 47.9755C71.334 46.6372 70.2519 45.5522 68.9169 45.5522C67.582 45.5522 66.4998 46.6372 66.4998 47.9755C66.4998 49.3137 67.582 50.3983 68.9169 50.3983Z"
        fill="#102A43"
      />
      <path
        d="M61.7278 53.0635H57.1201V54.1993H61.7278V53.0635Z"
        fill="#102A43"
      />
      <defs>
        <filter
          id="filter0_d_17_3590"
          x="21.4449"
          y="10.7095"
          width="77.7778"
          height="53.3335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-9" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_3590"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_3590"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_17_3590"
          x="0.83905"
          y="13.5781"
          width="119.306"
          height="87.5396"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_17_3590"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_17_3590"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
