import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetProjectStatisticsRequestFilterOptions,
  ProjectStatUiInterface,
  ProjectUiInterface,
} from 'api/requestWrappers/types/project';
import { useTheme } from 'styled-components';
import * as SC from './styles';
import MainContainer from '../../../../MainContainer';
import { useGetInitialValue } from '../../../../../../hooks/utils';
import { SelectOption } from '../../../../atomics/Select';
import Chart, { ChartColor, ChartUnit } from '../../../../Chart';

interface Props {
  projects: ProjectUiInterface[];
  projectStats: ProjectStatUiInterface[];
  onChangeActiveProjectId: (newActiveProjectId: number) => void;
  onChangeActivePeriod: (
    newActivePeriod: GetProjectStatisticsRequestFilterOptions
  ) => void;
  isLoading?: boolean;
}

export const statisticsPeriods = [
  {
    value: GetProjectStatisticsRequestFilterOptions['24H'],
    label: 'Last 24 hours',
  },
  {
    value: GetProjectStatisticsRequestFilterOptions['7Days'],
    label: 'Last 7 days',
  },
  {
    value: GetProjectStatisticsRequestFilterOptions['30Days'],
    label: 'Last 30 days',
  },
];

export default function Statistics({
  projects,
  projectStats,
  onChangeActiveProjectId,
  onChangeActivePeriod,
  isLoading,
}: Props) {
  const initialActiveProject = useGetInitialValue<SelectOption | undefined>(
    projects?.[0]?.id
      ? {
          value: projects[0].id,
          label: projects[0].name,
        }
      : undefined
  );
  const [activeProject, setActiveProject] = useState<SelectOption | undefined>(
    undefined
  );
  const [activePeriod, setActivePeriod] = useState(statisticsPeriods[0]);

  const { t } = useTranslation();
  const theme = useTheme();

  const colors: ChartColor[] = useMemo(
    () => [
      {
        id: `id${Math.random()}`,
        stroke: theme.palette.primary[500],
        fill: theme.palette.primary[500],
      },
    ],
    []
  );

  useEffect(() => {
    if (initialActiveProject) {
      setActiveProject(initialActiveProject);
    }
  }, [initialActiveProject]);

  return (
    <MainContainer>
      <SC.Container>
        <SC.Header>
          <SC.StyledSelect
            label={t('statistics.projectSelect.label')}
            activeOption={activeProject}
            options={projects.map((project) => ({
              value: project?.id,
              label: project?.name,
            }))}
            onSelect={(option) => {
              onChangeActiveProjectId(+option.value);
              setActiveProject(option);
            }}
            disabled={isLoading}
          />
          <SC.StyledSelect
            label={t('statistics.periodSelect.label')}
            activeOption={activePeriod}
            options={statisticsPeriods}
            onSelect={(option) => {
              const enumValue =
                option.value as GetProjectStatisticsRequestFilterOptions;
              onChangeActivePeriod(enumValue);
              setActivePeriod({
                value: enumValue,
                label: option.label,
              });
            }}
            disabled={isLoading}
          />
        </SC.Header>

        <SC.ChartWrapper>
          <Chart
            title={t('statistics.chartTitle')}
            subtitle={String(
              projects.find((project) => project.id === activeProject?.value)
                ?.requestCount || 0
            )}
            isLoading={isLoading}
            colors={colors}
            data={{
              meta: {
                value: {
                  color: colors[0],
                  unit: ChartUnit.REQUESTS,
                },
              },
              data:
                projectStats?.map((projectStat) => ({
                  timestamp: projectStat.hourTimestamp.getTime(),
                  value: projectStat.count,
                })) || [],
            }}
          />
        </SC.ChartWrapper>
      </SC.Container>
    </MainContainer>
  );
}
