import { ReactElement } from 'react';
import * as SC from './styles';
import Toolbar from './Toolbar';

export default function LoggedInLayout({
  children,
}: {
  children: ReactElement;
}) {
  return (
    <SC.Container>
      <Toolbar />
      <SC.Content>
        {children}
        <SC.Copyright>powered by DigitalMOB</SC.Copyright>
      </SC.Content>
    </SC.Container>
  );
}
