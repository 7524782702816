import { AxiosRequestConfig } from 'axios';
import AuthClientStore from 'clientStore/AuthClientStore';
import TypeChecker from 'helpers/classes/TypeChecker';
import AbstractRequest from './AbstractRequest';
import { ApiMethod, AUTH_ME_SUBROUTE } from './constants';
import {
  AuthData,
  ChangeEmailRequestData,
  ChangePasswordRequestData,
  ConfirmEmailApiRequestData,
  LoginApiResponse,
  MeApiResponse,
  RegisterData,
  ResendConfirmationEmailRequestData,
  ResetPasswordRequestData,
  SendPasswordResetLinkRequestData,
} from './types/auth';

export class AuthRequest extends AbstractRequest {
  private readonly authClientStoreService: typeof AuthClientStore;

  private _token: string | null;

  private requestInterceptorId: number | null;

  public static CONFIRM_EMAIL_QUERY_PARAMS = Object.freeze({
    CODE: 'code',
    EMAIL: 'email',
  });

  constructor(
    authClientStoreService: typeof AuthClientStore = AuthClientStore
  ) {
    super();
    this.authClientStoreService = authClientStoreService;
    this._token = authClientStoreService.getToken();
    this.requestInterceptorId = null;
  }

  get apiResource(): string {
    return '';
  }

  get routes(): Record<string, string> {
    return {
      LOGIN: `${this.apiUrl}/login`,
      REGISTER: `${this.apiUrl}/register`,
      ME: `${this.apiUrl}${AUTH_ME_SUBROUTE}`,
      CONFIRM_EMAIL: `${this.apiUrl}/confirm-email`,
      RESEND_CONFIRMATION_EMAIL: `${this.apiUrl}/resend`,
      SEND_PASSWORD_RESET_LINK: `${this.apiUrl}/send-reset-link`,
      RESET_PASSWORD: `${this.apiUrl}/reset-password`,
      CHANGE_EMAIL: `${this.apiUrl}/change-email`,
      CHANGE_PASSWORD: `${this.apiUrl}/change-password`,
      DELETE_ACCOUNT: `${this.apiUrl}/delete-account`,
    };
  }

  get token(): string | null {
    return this._token;
  }

  set token(token: string | null) {
    if (token) {
      this.authClientStoreService.setToken(token);
    } else {
      this.authClientStoreService.removeToken();
    }

    this._token = token;
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }
    this.requestInterceptorId = this.addRequestInterceptor(
      (config: AxiosRequestConfig) => {
        if (config?.headers) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${this.token}`;
        }

        return config;
      }
    );
  }

  unsetAuthorizationHeader() {
    if (TypeChecker.isNumber(this.requestInterceptorId)) {
      this.removeRequestInterceptor(this.requestInterceptorId);
    }
    this.requestInterceptorId = null;
  }

  async login(data: AuthData): Promise<LoginApiResponse> {
    const responseData: LoginApiResponse = (
      await this.request(ApiMethod.POST, this.routes.LOGIN, data)
    ).data;

    this.token = responseData.token;
    this.setAuthorizationHeader();

    return responseData;
  }

  logout(): null {
    this.token = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me(): Promise<MeApiResponse> {
    this.setAuthorizationHeader();
    try {
      const result = await this.request(ApiMethod.GET, this.routes.ME);

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }

  async register(data: RegisterData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.REGISTER,
      data
    );

    return result.data;
  }

  async confirmEmail(data: ConfirmEmailApiRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CONFIRM_EMAIL,
      data
    );

    return result.data;
  }

  async resendConfirmationEmail(data: ResendConfirmationEmailRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.RESEND_CONFIRMATION_EMAIL,
      data
    );

    return result.data;
  }

  async sendPasswordResetLink(data: SendPasswordResetLinkRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.SEND_PASSWORD_RESET_LINK,
      data
    );

    return result.data;
  }

  async resetPassword(data: ResetPasswordRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.RESET_PASSWORD,
      data
    );

    return result.data;
  }

  async changeEmail(data: ChangeEmailRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CHANGE_EMAIL,
      data
    );

    return result.data;
  }

  async changePassword(data: ChangePasswordRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.CHANGE_PASSWORD,
      data
    );

    return result.data;
  }

  async deleteAccount() {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_ACCOUNT
    );

    return result.data;
  }
}

export const authRequest = new AuthRequest();
