import { SVGProps } from 'react';

export default function FilinfoLogoAlt24px(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="2" width="24" height="24" rx="8" fill="white" />
      <rect
        x="14"
        y="1"
        width="11"
        height="11"
        rx="5.5"
        fill="#A2A5FC"
        stroke="#5D55FA"
        strokeWidth="2"
      />
    </svg>
  );
}
