import { SVGProps } from 'react';

export default function CopyIcon24px(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 19L9 19C7.34315 19 6 17.6569 6 16L6 7C5.44772 7 5 7.44772 5 8V19C5 19.5523 5.44772 20 6 20H14C14.5523 20 15 19.5523 15 19ZM15.9985 17L9 17C8.44772 17 8 16.5523 8 16L8 6V5C8 4.44772 8.44772 4 9 4L13.5 4L15.7816 4C16.0784 4 16.3599 4.13182 16.5498 4.35982L17.5 5.5L18.7593 6.96913C18.9146 7.15038 19 7.38121 19 7.61992V10.5L19 16C19 16.5523 18.5523 17 18 17H16.0015C16.001 17 16.0005 17 16 17C15.9995 17 15.999 17 15.9985 17ZM17 19C17 20.6569 15.6569 22 14 22H6C4.34315 22 3 20.6569 3 19V8C3 6.34315 4.34315 5 6 5C6 3.34315 7.34314 2 9 2H13.5H15.7816C16.672 2 17.5163 2.39547 18.0863 3.07945L19.0276 4.20898L20.2778 5.66755C20.7438 6.21128 21 6.90379 21 7.61992V10.5V16C21 17.6569 19.6569 19 18 19L17 19ZM10 7C10 6.44772 10.4477 6 11 6H15C15.5523 6 16 6.44772 16 7C16 7.55229 15.5523 8 15 8H11C10.4477 8 10 7.55229 10 7ZM11 9.5C10.4477 9.5 10 9.94772 10 10.5C10 11.0523 10.4477 11.5 11 11.5H16C16.5523 11.5 17 11.0523 17 10.5C17 9.94772 16.5523 9.5 16 9.5H11ZM10 14C10 13.4477 10.4477 13 11 13H16C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15H11C10.4477 15 10 14.5523 10 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
