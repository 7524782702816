import styled from 'styled-components';
import ButtonWrapper from '../../../../atomics/ButtonWrapper';
import { Button } from '../../../../atomics/Button';
import MainContainer from '../../../../MainContainer';

export const StyledMainContainer = styled(MainContainer)`
  flex-grow: 1;
`;

export const Container = styled.div<{ hasProjects: boolean }>`
  height: 100%;
  width: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasProjects }) => (hasProjects ? 'start' : 'center')};
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const Title = styled.h2<{ hasProjects: boolean }>`
  display: none;
  margin: 0 0 24px 0;
  align-self: start;
  ${({ theme }) => theme.typography.header.h2};

  @media only screen and (max-width: 768px) {
    display: ${({ hasProjects }) => (hasProjects ? 'block' : 'none')};
  }
`;

export const DesktopCreateProjectButton = styled(Button)`
  display: inline-block;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileCreateProjectButton = styled(ButtonWrapper)`
  display: none;
  margin-right: -4px;
  color: ${({ theme }) => theme.palette.primary[500]};
  ${({ theme }) => theme.typography.paragraph.p3};
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    display: inline-block;
  }
`;
