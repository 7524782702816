import styled from 'styled-components';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const NavLink = styled(ReactRouterNavLink)`
  position: relative;
  text-decoration: none;
  ${({ theme }) => theme.typography.paragraph.p3};
  color: ${({ theme }) => theme.palette.neutrals[500]};

  &.active {
    color: ${({ theme }) => theme.palette.neutrals[900]};
    ::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -27px;
      border-bottom: 2px solid ${({ theme }) => theme.palette.primary[500]};
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
