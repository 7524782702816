import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateProjectModalProps from './types';
import Modal from '../../../../../atomics/Modal';
import Input from '../../../../../atomics/Input';
import { Button } from '../../../../../atomics/Button';

export default function CreateProjectModal({
  isOpen,
  isLoading,
  onSubmit,
  onClose,
}: CreateProjectModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setName('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      title={t('dashboard.createANewProject')}
    >
      <Input
        disabled={isLoading}
        label={t('dashboard.projectName')}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button
        disabled={!name}
        isLoading={isLoading}
        onClick={() => onSubmit(name)}
      >
        {t('dashboard.createProject')}
      </Button>
    </Modal>
  );
}
