import { InputHTMLAttributes, ReactNode, useId } from 'react';
import clsx from 'clsx';

import * as SC from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  labelSecondary?: string | ReactNode;
  error?: string;
  className?: string;
}

function Input({
  label,
  labelSecondary,
  value,
  error,
  className,
  ...rest
}: InputProps) {
  const id = useId();

  return (
    <SC.Label htmlFor={id} className={className}>
      {label || labelSecondary ? (
        <SC.TitleWrapper>
          <SC.Title>{label}</SC.Title>
          {labelSecondary}
        </SC.TitleWrapper>
      ) : null}

      <SC.Input
        id={id}
        type="text"
        className={clsx({ isEmpty: !value, error })}
        value={value}
        {...rest}
      />

      {error && <SC.Error>{error}</SC.Error>}
    </SC.Label>
  );
}

export default Input;
