import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  &[disabled] {
    cursor: not-allowed;
  }
`;
