import { ReactElement } from 'react';

import * as SC from './styles';

interface MainContainerProps {
  children: ReactElement | ReactElement[];
  className?: string;
}
export default function MainContainer({
  children,
  className,
}: MainContainerProps) {
  return <SC.Wrapper className={className}>{children}</SC.Wrapper>;
}
