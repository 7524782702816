export const FILFINFO_ENV = process.env.REACT_APP_FILFINFO_ENV || 'dev';

export const FILINFO_API_PROTOCOL =
  process.env.REACT_APP_FILINFO_API_PROTOCOL || 'http';

export const FILINFO_API_HOST =
  process.env.REACT_APP_FILINFO_API_HOST || 'localhost';

export const FILINFO_API_PORT = process.env.REACT_APP_FILINFO_API_PORT || '';

export const APP_DEFAULT_TITLE =
  process.env.REACT_APP_APP_DEFAULT_TITLE || 'Filinfo';

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === 'true';
