import styled from 'styled-components';

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(16, 42, 67, 0.8);
  backdrop-filter: blur(16px);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  padding: 32px;
  border-radius: 8px;
  width: 464px;
  transition: transform 0.2s ease;
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : '100vh')});
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    ${({ theme }) => theme.typography.header.h3};
    margin: 0;
    font-weight: 700;
  }
`;
