import { css } from 'styled-components';

export default {
  palette: {
    neutrals: {
      50: '#F0F4F8',
      100: '#D9E2EC',
      200: '#BCCCDC',
      300: '#9FB3C8',
      400: '#829AB1',
      500: '#627D98',
      RGB_500: '98, 125, 152',
      600: '#486581',
      700: '#334E68',
      800: '#243B53',
      900: '#102A43',
      RGB_900: '16, 42, 67',
    },
    primary: {
      50: '#E6E6FF',
      100: '#C4C6FF',
      200: '#A2A5FC',
      300: '#8888FC',
      400: '#7069FA',
      500: '#5D55FA',
      RGB_500: '93, 85, 250',
      600: '#4D3DF7',
      700: '#3525E6',
      800: '#1D0EBE',
      900: '#0C008C',
    },
    supporting: {
      flatWhite: '#FFFFFF',
      white: '#FAFAFB',
      error: '#BA2525',
      RGB_error: '186, 37, 37',
      warning: '#E67635',
      RGB_warning: '230, 118, 53',
      success: '#27AB83',
      RGB_success: '39, 171, 131',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '24px',
    header: {
      h1: css({
        fontSize: 56,
        lineHeight: '60px',
        fontWeight: 800,
        letterSpacing: 0,
        textAlign: 'left',
      }),
      h2: css({
        fontSize: 20,
        lineHeight: '32px',
        fontWeight: 700,
        letterSpacing: 0,
        textAlign: 'left',
      }),
      h3: css({
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 700,
        letterSpacing: 0,
        textAlign: 'left',
      }),
    },
    paragraph: {
      p1: css({
        fontSize: 18,
        lineHeight: '32px',
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'left',
      }),
      p2: css({
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'left',
      }),
      p3: css({
        fontSize: 14,
        lineHeight: '24px',
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'left',
      }),
    },
    caption: {
      c1: css({
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 500,
        letterSpacing: 0,
        textAlign: 'left',
      }),
    },
  },
};
