export default {
  statistics: {
    projectSelect: {
      label: 'Project',
    },
    periodSelect: {
      label: 'Period',
    },
    chartTitle: 'Requests volume',
  },
};
