import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ProjectRequest,
  projectRequest,
} from '../../api/requestWrappers/ProjectRequest';
import {
  AddProjectRequestData,
  DeleteProjectRequestData,
  GetProjectsResponseData,
  GetProjectStatisticsRequestData,
  GetProjectStatisticsResponseData,
  ProjectStatUiInterface,
  ProjectUiInterface,
  RenameProjectRequestData,
} from '../../api/requestWrappers/types/project';
import axiosBaseQuery from '../rtkCustomQueries/axiosBaseQuery';
import ProjectUiHelper from '../../api/requestWrappers/uiAdapters/ProjectUiAdapter';
import ProjectStatUiHelper from '../../api/requestWrappers/uiAdapters/ProjectStatUiAdapter';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: axiosBaseQuery<ProjectRequest>(projectRequest),
  tagTypes: ['projectTag', 'projectStatisticsTag'],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    getProjects: build.query({
      query: (arg) => ({
        classMethod: 'getProjects',
        classMethodParameters: arg,
      }),
      transformResponse: (baseQueryReturnValue: GetProjectsResponseData[]) =>
        Promise.all(
          baseQueryReturnValue.map((apiProject) =>
            ProjectUiHelper.getUiModel(apiProject)
          )
        ),
      providesTags: ['projectTag'],
    }),
    findProject: build.query<ProjectUiInterface, number>({
      query: (arg) => ({
        classMethod: 'findProject',
        classMethodParameters: [arg],
      }),
      transformResponse: (baseQueryReturnValue: GetProjectsResponseData) =>
        ProjectUiHelper.getUiModel(baseQueryReturnValue),
      providesTags: ['projectTag'],
    }),
    addProject: build.mutation<ProjectUiInterface, AddProjectRequestData>({
      query: (arg) => ({
        classMethod: 'addProject',
        classMethodParameters: [arg],
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        }

        return ['projectTag'];
      },
    }),
    renameProject: build.mutation<ProjectUiInterface, RenameProjectRequestData>(
      {
        query: (arg) => ({
          classMethod: 'renameProject',
          classMethodParameters: [arg],
        }),
        invalidatesTags: (result, error) => {
          if (error) {
            return [];
          }

          return ['projectTag'];
        },
      }
    ),
    deleteProject: build.mutation<ProjectUiInterface, DeleteProjectRequestData>(
      {
        query: (arg) => ({
          classMethod: 'deleteProject',
          classMethodParameters: [arg],
        }),
        invalidatesTags: (result, error) => {
          if (error) {
            return [];
          }

          return ['projectTag'];
        },
      }
    ),
    getProjectStatistics: build.query<
      ProjectStatUiInterface[],
      GetProjectStatisticsRequestData
    >({
      query: (arg) => ({
        classMethod: 'getProjectStatistics',
        classMethodParameters: [arg],
      }),
      transformResponse: (
        baseQueryReturnValue: GetProjectStatisticsResponseData[]
      ) =>
        Promise.all(
          baseQueryReturnValue.map((apiProjectStat) =>
            ProjectStatUiHelper.getUiModel(apiProjectStat)
          )
        ),
      providesTags: ['projectStatisticsTag'],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useFindProjectQuery,
  useAddProjectMutation,
  useRenameProjectMutation,
  useDeleteProjectMutation,
  useGetProjectStatisticsQuery,
} = projectApi;
