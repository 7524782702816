import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ButtonColor } from './constant';
import { rotation } from '../../../../styles/global';

const getButtonStyles = (color: ButtonColor) => {
  switch (color) {
    case ButtonColor.DANGER_ALT:
      return css`
        color: ${({ theme }) => theme.palette.supporting.error};
        background: rgba(
          ${({ theme }) => theme.palette.supporting.RGB_error},
          0.08
        );

        &:hover,
        &:focus {
          background: rgba(
            ${({ theme }) => theme.palette.supporting.RGB_error},
            0.24
          );
        }

        &:active {
          background: rgba(
            ${({ theme }) => theme.palette.supporting.RGB_error},
            0.32
          );
        }
      `;
    case ButtonColor.DANGER:
      return css`
        color: ${({ theme }) => theme.palette.supporting.flatWhite};
        background: ${({ theme }) => theme.palette.supporting.error};

        &:hover,
        &:focus {
          background-blend-mode: color;
          background: linear-gradient(0deg, #ba2525, #ba2525),
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.2),
              rgba(255, 255, 255, 0.2)
            );
        }

        &:active {
          background-blend-mode: color;
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0.2)
            ),
            linear-gradient(0deg, #ba2525, #ba2525);
        }
      `;
    case ButtonColor.GHOST:
      return css`
        color: ${({ theme }) => theme.palette.neutrals[900]};
        background: ${({ theme }) => theme.palette.supporting.flatWhite};
        border: 1px solid ${({ theme }) => theme.palette.neutrals[100]};

        &:hover,
        &:focus {
          background: ${({ theme }) => theme.palette.neutrals[50]};
        }

        &:active {
          background: ${({ theme }) => theme.palette.neutrals[100]};
        }
      `;
    case ButtonColor.SECONDARY:
      return css`
        color: ${({ theme }) => theme.palette.primary[500]};
        background: rgba(${({ theme }) => theme.palette.primary.RGB_500}, 0.08);

        &:hover,
        &:focus {
          background: rgba(
            ${({ theme }) => theme.palette.primary.RGB_500},
            0.24
          );
        }

        &:active {
          background: rgba(
            ${({ theme }) => theme.palette.primary.RGB_500},
            0.32
          );
        }
      `;

    case ButtonColor.PRIMARY:
    default:
      return css`
        color: ${({ theme }) => theme.palette.supporting.flatWhite};
        background: ${({ theme }) => theme.palette.primary[500]};

        &:hover,
        &:focus {
          background: ${({ theme }) => theme.palette.primary[400]};
        }

        &:active {
          background: ${({ theme }) => theme.palette.primary[600]};
        }
      `;
  }
};

const defaultStyles = css`
  ${({ theme }) => theme.typography.paragraph.p3};
  font-weight: 700;
  cursor: pointer;
  padding: 12px 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  text-align: center;

  &[disabled] {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.neutrals[300]};
    background: ${({ theme }) => theme.palette.neutrals[50]};
    border-color: ${({ theme }) => theme.palette.neutrals[100]};
  }
`;

export const Button = styled.button<{ $color: ButtonColor }>`
  ${defaultStyles};
  ${({ disabled, $color }) => !disabled && getButtonStyles($color)};
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg {
    animation-name: ${rotation};
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
`;

export const Link = styled(NavLink)<{
  $color: ButtonColor;
  disabled?: boolean;
}>`
  text-decoration: none;
  ${defaultStyles};
  ${({ disabled, $color }) => !disabled && getButtonStyles($color)};
`;
