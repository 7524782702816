import { SVGProps } from 'react';

export default function UserIcon24Px(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.6956C11.2179 16.6956 10.5908 16.7507 10.0557 16.8325C7.85024 17.1694 6 19.5109 6 21.913C6 22.4893 5.55228 22.9565 5 22.9565C4.44772 22.9565 4 22.4893 4 21.913C4 19.2072 5.62494 16.5494 7.9591 15.3564C6.1687 14.0333 5 11.8547 5 9.39126C5 5.35718 8.13401 2.08691 12 2.08691C15.866 2.08691 19 5.35718 19 9.39126C19 11.8547 17.8313 14.0333 16.0409 15.3564C18.3751 16.5494 20 19.2072 20 21.913C20 22.4893 19.5523 22.9565 19 22.9565C18.4477 22.9565 18 22.4893 18 21.913C18 19.5109 16.1498 17.1694 13.9443 16.8325C13.4092 16.7507 12.7821 16.6956 12 16.6956ZM12 14.6087C9.23858 14.6087 7 12.2727 7 9.39126C7 6.50978 9.23858 4.17387 12 4.17387C14.7614 4.17387 17 6.50978 17 9.39126C17 12.2727 14.7614 14.6087 12 14.6087Z"
        fill="#627D98"
      />
    </svg>
  );
}
