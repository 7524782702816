import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedStatisticsPage = lazy(
  () =>
    import('components/smart/layouts/LoggedInLayout/ConnectedStatisticsPage')
);

const STATISTICS: Route = {
  component: ConnectedStatisticsPage,
  path: PATH.STATISTICS,
  isProtected: true,
};

export default [STATISTICS];
