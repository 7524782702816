export default {
  auth: {
    sidebar: {
      title: 'Filinfo',
      paragraph:
        'Filinfo is a fast and lightweight source of Filecoin chain data, no Lotus node required. Filinfo provides access to all the messages included in every block of the Filecoin blockchain. Check the <docsLink>Documentation</docsLink> page for more info.',
    },
    changePasswordForm: {
      title: 'Change your password',
      password1Input: {
        label: 'New password',
        placeholder: 'Password',
      },
      password2Input: {
        label: 'Confirm new password',
        placeholder: 'Password',
      },
      submitButton: 'Change password',
    },
    loginForm: {
      title: 'Log in to your account',
      emailInput: {
        label: 'Email address',
        placeholder: 'Email',
      },
      passwordInput: {
        label: 'Password',
        placeholder: 'Password',
        forgotPassword: 'Forgot password?',
      },
      submitButton: 'Log In',
      footer: 'Not registered yet? <signUpLink>Sign up</signUpLink>',
    },
    resetPasswordForm: {
      title: 'Reset your password',
      emailInput: {
        label: 'Email address',
        placeholder: 'Email address',
      },
      submitButton: 'Reset Password',
      emailMessage: {
        title: 'Check your email',
        p1: 'An email has been sent to <highlight>{{email}}</highlight> with a link to reset your password.',
        p2: 'Please check your email. If you have not received the email after a few minutes, please check your spam folder.',
      },
    },
    signupForm: {
      title: 'Create a new account',
      emailInput: {
        label: 'Email address',
        placeholder: 'Email',
      },
      passwordInput: {
        label: 'Password',
        placeholder: 'Password',
      },
      submitButton: 'Sign Up',
      footer: 'You already have an account? <logInLink>Log In</logInLink>',
      emailMessage: {
        title: 'Check your email',
        p1: 'An email has been sent to <highlight>{{email}}</highlight> with a link to verify your account',
        p2: 'Please check your email. If you have not received the email after a few minutes, please check your spam folder.',
        resendButton: 'Resend email',
      },
    },
    emailConfirmed: {
      title: 'Email confirmation',
      p: 'Your email <highlight>{{email}}</highlight> has been confirmed successfully. You can log in below.',
      error: 'An error has occurred. Please try again later.',
    },
  },
};
