import { Navigate } from 'react-router-dom';
import PATH from 'routes/paths';
import { ElementType, ReactElement } from 'react';
import { isAuthenticatedSelector } from 'redux/slices/selectors';
import { useAppSelector } from 'hooks/redux';

export default function AppRoute({
  component: Component,
  isProtected,
  redirectIfAuthenticated,
}: {
  component: ElementType;
  isProtected: boolean;
  redirectIfAuthenticated?: boolean;
}): ReactElement {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  if (isProtected && !isAuthenticated) {
    return <Navigate to={PATH.AUTH.LOGIN} replace />;
  }

  if (redirectIfAuthenticated && isAuthenticated) {
    return <Navigate to={PATH.INDEX} replace />;
  }

  return <Component />;
}
