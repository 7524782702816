import { ReactNode } from 'react';
import * as ReactDOM from 'react-dom';

export default function Portal({
  children,
  container,
}: {
  children: ReactNode;
  container: HTMLElement;
}) {
  return ReactDOM.createPortal(children, container);
}
