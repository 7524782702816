import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import PATH from 'routes/paths';
import { useAppDispatch } from 'hooks/redux';
import { logout } from 'redux/slices/auth';
import * as SC from './styles';
import ToolbarMyProfileMenuItem from './ToolbarMyProfileMenuItem';
import ToolbarMenuItem from './ToolbarMenuItem';
import FilinfoLogo24px from '../../../icons/FilinfoLogo24px';
import MenuIcon24px from '../../../icons/MenuIcon24px';
import MobileMenu from './mobile/MobileMenu';
import CloseIcon24px from '../../../icons/CloseIcon24px';
import { ToolbarContext } from '../../../../../contexts/ToolbarProvider';

export default function Toolbar() {
  const { t } = useTranslation();
  const [mobileMenuIsActive, setMobileMenuIsActive] = useState(false);
  const { setToolbarPortalNode } = useContext(ToolbarContext);
  const dispatch = useAppDispatch();

  return (
    <SC.Wrapper>
      <SC.StyledMainContainer>
        <SC.Container>
          <SC.LogoLink
            to={PATH.INDEX}
            onClick={() => {
              if (mobileMenuIsActive) {
                setMobileMenuIsActive(false);
              }
            }}
          >
            <FilinfoLogo24px />
            <span>Filinfo</span>
          </SC.LogoLink>
          <ToolbarMenuItem to={PATH.INDEX}>
            {t('general.navbar.dashboard')}
          </ToolbarMenuItem>
          <ToolbarMenuItem to={PATH.STATISTICS}>
            {t('general.navbar.statistics')}
          </ToolbarMenuItem>
          <ToolbarMenuItem to={PATH.DOCS}>
            {t('general.navbar.documentation')}
          </ToolbarMenuItem>
          <SC.PortalContainer
            ref={setToolbarPortalNode}
            className="portal-container"
          />
          <ToolbarMyProfileMenuItem />
          <SC.MobileMenuButton>
            {mobileMenuIsActive ? (
              <CloseIcon24px onClick={() => setMobileMenuIsActive(false)} />
            ) : (
              <MenuIcon24px onClick={() => setMobileMenuIsActive(true)} />
            )}
          </SC.MobileMenuButton>
          <MobileMenu
            isActive={mobileMenuIsActive}
            onMenuItemClick={() => setMobileMenuIsActive(false)}
            onLogout={() => dispatch(logout())}
          />
        </SC.Container>
      </SC.StyledMainContainer>
    </SC.Wrapper>
  );
}
