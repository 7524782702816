import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const ToolbarContext = createContext<{
  toolbarPortalNode: HTMLElement;
  setToolbarPortalNode: CallbackFunction;
}>(null);

export default function ToolbarProvider({ children }: { children: ReactNode }) {
  const [toolbarPortalNode, setToolbarPortalNode] = useState(null);

  const optimizedSetToolbarPortalNode = useCallback((node: HTMLElement) => {
    if (node) {
      setToolbarPortalNode(node);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      toolbarPortalNode,
      setToolbarPortalNode: optimizedSetToolbarPortalNode,
    }),
    [toolbarPortalNode, optimizedSetToolbarPortalNode]
  );

  return (
    <ToolbarContext.Provider value={contextValue}>
      {children}
    </ToolbarContext.Provider>
  );
}
