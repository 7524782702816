import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logout } from 'redux/slices/auth';
import { useAppDispatch } from 'hooks/redux';
import PATH from 'routes/paths';
import UserIcon24Px from 'components/dumb/icons/UserIcon24px';
import Dropdown from 'components/dumb/atomics/Dropdown';
import * as SC from './styles';

export default function ToolbarMyProfileMenuItem() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <SC.ContainerLink>
      <Dropdown
        items={[
          {
            label: t('general.navbar.accountSettings'),
            onClick: () => navigate(PATH.ACCOUNT_SETTINGS),
          },
          {
            label: t('general.navbar.logout'),
            onClick: () => dispatch(logout()),
          },
        ]}
      >
        <UserIcon24Px />
      </Dropdown>
    </SC.ContainerLink>
  );
}
