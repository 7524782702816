export default {
  dashboard: {
    createNewProject: 'Create new project',
    createANewProject: 'Create a new project',
    createProject: 'Create project',
    projectName: 'Project name',
    noProjectCard: {
      title: 'No project created',
      description: 'Create your first project to start using Filinfo',
    },
    projectCard: {
      status: 'Status',
      created: 'Created',
      requestsToday: 'Requests today',
    },
  },
};
