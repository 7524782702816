import AbstractRequest from './AbstractRequest';
import {
  AddProjectRequestData,
  DeleteProjectRequestData,
  GetProjectsResponseData,
  GetProjectStatisticsRequestData,
  GetProjectStatisticsResponseData,
  RenameProjectRequestData,
} from './types/project';
import { ApiMethod } from './constants';

export class ProjectRequest extends AbstractRequest {
  get apiResource(): string {
    return '';
  }

  get routes(): Record<string, string> {
    return {
      GET_PROJECTS: `${this.apiUrl}/profile`,
      FIND_PROJECT: `${this.apiUrl}/profile`,
      ADD_PROJECT: `${this.apiUrl}/add-project`,
      RENAME_PROJECT: `${this.apiUrl}/rename-project`,
      DELETE_PROJECT: `${this.apiUrl}/delete-project`,
      GET_PROJECT_STATISTICS: `${this.apiUrl}/statistics`,
    };
  }

  async getProjects(): Promise<GetProjectsResponseData[]> {
    const result = await this.request(ApiMethod.GET, this.routes.GET_PROJECTS);

    return result.data.projects;
  }

  async findProject(id: number): Promise<GetProjectsResponseData> {
    const result = await this.request(ApiMethod.GET, this.routes.FIND_PROJECT);

    return result.data.projects.find(
      (project: GetProjectsResponseData) => project.id === id
    );
  }

  async addProject(data: AddProjectRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.ADD_PROJECT,
      data
    );

    return result.data;
  }

  async renameProject(data: RenameProjectRequestData) {
    const result = await this.request(
      ApiMethod.POST,
      this.routes.RENAME_PROJECT,
      data
    );

    return result.data;
  }

  async deleteProject(data: DeleteProjectRequestData) {
    const result = await this.request(
      ApiMethod.DELETE,
      this.routes.DELETE_PROJECT,
      data
    );

    return result.data;
  }

  async getProjectStatistics(
    data: GetProjectStatisticsRequestData
  ): Promise<GetProjectStatisticsResponseData[]> {
    const result = await this.request(
      ApiMethod.POST,
      `${this.routes.GET_PROJECT_STATISTICS}`,
      data
    );

    return result.data;
  }
}

export const projectRequest = new ProjectRequest();
