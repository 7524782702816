// eslint-disable-next-line max-classes-per-file
import { AbstractUiAdapter } from 'simple-abstract-ui-data-adapter';
import {
  GetProjectStatisticsResponseData,
  ProjectStatUiInterface,
} from '../types/project';

class ProjectStatUiAdapter
  extends AbstractUiAdapter<GetProjectStatisticsResponseData>
  implements ProjectStatUiInterface
{
  count: number;

  id: string;

  projectName: string;

  hourTimestamp: Date;

  protected applyAttributes(
    apiProjectStat: GetProjectStatisticsResponseData
  ): void {
    this.count = +apiProjectStat.count;
    this.id = apiProjectStat.id.toString();
    this.projectName = apiProjectStat.name;
    this.hourTimestamp = new Date(apiProjectStat.timestamp);
  }

  protected applyRuntimeValidations() {
    if (Number.isNaN(this.hourTimestamp.getTime())) {
      throw new Error('Invalid date string received from api!');
    }
  }
}

export default class ProjectStatUiHelper {
  public static async getUiModel(
    apiProjectStat: GetProjectStatisticsResponseData
  ): Promise<ProjectStatUiInterface> {
    return ProjectStatUiAdapter.create<
      GetProjectStatisticsResponseData,
      ProjectStatUiInterface
    >(apiProjectStat);
  }
}
