import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authRequest } from 'api/requestWrappers/AuthRequest';
import { AuthData } from 'api/requestWrappers/types/auth';

export const login = createAsyncThunk('auth/login', (authData: AuthData) =>
  authRequest.login(authData)
);

export const logout = createAsyncThunk('auth/logout', () =>
  authRequest.logout()
);

export const me = createAsyncThunk('auth/me', () => authRequest.me());

export interface AuthState {
  user: {
    id: number | null;
    email: string | null;
  };
  isLoginLoading: boolean;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: {
    id: null,
    email: null,
  },
  isLoginLoading: false,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state: AuthState) => {
      state.isLoginLoading = true;
    });
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      state.user = action.payload.account;
      state.isAuthenticated = true;
      state.isLoginLoading = false;
    });
    builder.addCase(login.rejected, (state: AuthState) => {
      state.user = initialState.user;
      state.isAuthenticated = false;
      state.isLoginLoading = false;
    });
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
    builder.addCase(me.fulfilled, (state: AuthState, action) => {
      state.user = { ...state.user, ...action.payload.account };
      state.isAuthenticated = true;
    });
    builder.addCase(me.rejected, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
  },
});

export default authSlice.reducer;
