import { ReactNode, useState } from 'react';

import * as SC from './styles';

interface Props {
  children: ReactNode;
  text: string;
}

const animationDuration = 2000;

export default function CopyToClipboard({ children, text }: Props) {
  const [copied, setCopied] = useState<boolean | undefined>(undefined);

  const copyToClipboard = () => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(text).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(undefined), animationDuration);
      });
      return;
    }

    setCopied(false);
    setTimeout(() => setCopied(undefined), animationDuration);
  };

  return (
    <SC.Button type="button" onClick={() => copyToClipboard()}>
      {children}

      <SC.CopyIcon $copied={copied} />
    </SC.Button>
  );
}
