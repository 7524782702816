import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedLoginPage = lazy(
  () => import('components/smart/layouts/AuthLayout/ConnectedLoginPage')
);
const ConnectedSignUpPage = lazy(
  () => import('components/smart/layouts/AuthLayout/ConnectedSignUpPage')
);
const ConnectedResetPasswordPage = lazy(
  () => import('components/smart/layouts/AuthLayout/ConnectedResetPasswordPage')
);
const ConnectedChangePasswordPage = lazy(
  () =>
    import('components/smart/layouts/AuthLayout/ConnectedChangePasswordPage')
);
const ConnectedEmailConfirmedPage = lazy(
  () =>
    import('components/smart/layouts/AuthLayout/ConnectedEmailConfirmedPage')
);

const LOGIN: Route = {
  component: ConnectedLoginPage,
  path: PATH.AUTH.LOGIN,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const SIGN_UP: Route = {
  component: ConnectedSignUpPage,
  path: PATH.AUTH.SIGN_UP,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const RESET_PASSWORD: Route = {
  component: ConnectedResetPasswordPage,
  path: PATH.AUTH.RESET_PASSWORD,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const CHANGE_PASSWORD: Route = {
  component: ConnectedChangePasswordPage,
  path: PATH.AUTH.CHANGE_PASSWORD,
  isProtected: false,
  redirectIfAuthenticated: true,
};

const EMAIL_CONFIRMED: Route = {
  component: ConnectedEmailConfirmedPage,
  path: PATH.AUTH.EMAIL_CONFIRMED,
  isProtected: false,
  redirectIfAuthenticated: true,
};

export default [
  LOGIN,
  SIGN_UP,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  EMAIL_CONFIRMED,
];
