import { ReactNode, useEffect, useId, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as SC from './styles';
import { useGetInitialValue } from '../../../../hooks/utils';
import TypeChecker from '../../../../helpers/classes/TypeChecker';

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps {
  label?: string | ReactNode;
  error?: string;
  className?: string;
  options: SelectOption[];
  activeOption: SelectOption;
  onSelect: (option: SelectOption) => void;
  disabled?: boolean;
}

function Select({
  label,
  error,
  className,
  activeOption,
  options,
  onSelect,
  disabled,
}: SelectProps) {
  const id = useId();
  const initialValue = useGetInitialValue<string | undefined>(
    activeOption?.label
  );
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (TypeChecker.isString(initialValue)) {
      setInputValue(initialValue);
    }
  }, [initialValue]);

  const { t } = useTranslation();

  const filteredOptions = useMemo(() => {
    if (inputValue.toLowerCase() !== activeOption?.label?.toLowerCase()) {
      return options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    return options;
  }, [inputValue, options, activeOption?.value]);

  return (
    <SC.Label htmlFor={id} className={className}>
      {label ? (
        <SC.TitleWrapper>
          <SC.Title>{label}</SC.Title>
        </SC.TitleWrapper>
      ) : null}

      <SC.Input
        disabled={disabled}
        id={id}
        type="text"
        className={clsx({ isEmpty: !inputValue, error })}
        value={inputValue || ''}
        onChange={(e) => setInputValue(e.target.value)}
      />

      <SC.StyledArrowUpIcon24px $hasLabel={Boolean(label)} />

      {error && <SC.Error>{error}</SC.Error>}

      <SC.Dropdown role="listbox" aria-labelledby={id}>
        {!filteredOptions.length ? (
          <SC.NoOptions>{t('general.select.noOptions')}</SC.NoOptions>
        ) : null}
        {filteredOptions.map((option) => (
          <SC.DropdownItem
            key={option.value}
            type="button"
            onClick={() => {
              onSelect(option);
              setInputValue(option.label);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              document.activeElement?.blur();
            }}
            tabIndex={0}
            role="option"
            aria-selected={activeOption?.value === option.value}
          >
            {option.label}
          </SC.DropdownItem>
        ))}
      </SC.Dropdown>
    </SC.Label>
  );
}

export default Select;
