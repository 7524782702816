import { format as formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ProjectUiInterface } from 'api/requestWrappers/types/project';
import displayNumber from 'helpers/functions/numbers';
import { getProjectStatusText } from 'helpers/functions/strings';

import PATH from 'routes/paths';
import { replaceId } from 'routes/patterns';
import * as SC from './styles';
import Skeleton from '../../../../../atomics/Skeleton';

interface Props {
  project?: ProjectUiInterface;
  isLoading?: boolean;
}

export default function ProjectCard({ project, isLoading }: Props) {
  const { t } = useTranslation();

  return (
    <SC.Container to={replaceId(PATH.PROJECT.CHILDREN.ONE, project?.id)}>
      <SC.ProjectData>
        {isLoading ? (
          <Skeleton width={40} height={40} borderRadius={12} />
        ) : (
          <SC.ProjectAvatar>
            <p>{project?.name[0].toUpperCase()}</p>
          </SC.ProjectAvatar>
        )}

        <SC.ProjectText>
          <p>
            {isLoading ? (
              <Skeleton width={140} height={24} borderRadius={4} />
            ) : (
              project?.name
            )}
          </p>
          <div>
            {isLoading ? (
              <Skeleton
                width={140}
                height={8}
                borderRadius={4}
                style={{ marginTop: 8 }}
              />
            ) : (
              <>
                {t('dashboard.projectCard.created')}{' '}
                {formatDate(project?.createdAt, 'MMMM d, yyyy')}
              </>
            )}
          </div>
        </SC.ProjectText>
      </SC.ProjectData>
      <SC.ProjectStatus status={project?.status}>
        <div>
          {isLoading ? (
            <Skeleton width={120} height={24} borderRadius={4} />
          ) : (
            t('dashboard.projectCard.status')
          )}
        </div>
        <p>
          {isLoading ? (
            <Skeleton
              width={120}
              height={8}
              borderRadius={4}
              style={{ marginTop: 8 }}
            />
          ) : (
            getProjectStatusText(project?.status)
          )}
        </p>
      </SC.ProjectStatus>
      <SC.ProjectRequestCountWrapper>
        <SC.ProjectRequestCount>
          <div>
            {isLoading ? (
              <Skeleton width={120} height={24} borderRadius={4} />
            ) : (
              t('dashboard.projectCard.requestsToday')
            )}
          </div>
          <p>
            {isLoading ? (
              <Skeleton
                width={120}
                height={8}
                borderRadius={4}
                style={{ marginTop: 8 }}
              />
            ) : (
              displayNumber(project?.requestCount)
            )}
          </p>
        </SC.ProjectRequestCount>
      </SC.ProjectRequestCountWrapper>
    </SC.Container>
  );
}
