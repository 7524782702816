import general from './general/en';
import dashboard from './dashboard/en';
import auth from './auth/en';
import statistics from './statistics/en';
import accountSettings from './accountSettings/en';
import project from './project/en';

const en = {
  translations: {
    ...general,
    ...dashboard,
    ...auth,
    ...statistics,
    ...accountSettings,
    ...project,
  },
};

export default en;
