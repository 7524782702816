import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PATH from 'routes/paths';
import * as SC from './styles';

export default function MobileMenu({
  isActive,
  onMenuItemClick,
  onLogout,
}: {
  isActive: boolean;
  onMenuItemClick: CallbackFunction;
  onLogout: CallbackFunction;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SC.Backdrop isActive={isActive}>
      <SC.Container>
        <SC.MenuItem
          onClick={(...args: unknown[]) => {
            navigate(PATH.INDEX);
            onMenuItemClick(...args);
          }}
        >
          {t('general.navbar.dashboard')}
        </SC.MenuItem>

        <SC.MenuItem
          onClick={(...args: unknown[]) => {
            navigate(PATH.STATISTICS);
            onMenuItemClick(...args);
          }}
        >
          {t('general.navbar.statistics')}
        </SC.MenuItem>

        <SC.MenuItem
          onClick={(...args: unknown[]) => {
            navigate(PATH.DOCS);
            onMenuItemClick(...args);
          }}
        >
          {t('general.navbar.docs')}
        </SC.MenuItem>

        <SC.MenuItem
          onClick={(...args: unknown[]) => {
            navigate(PATH.ACCOUNT_SETTINGS);
            onMenuItemClick(...args);
          }}
        >
          {t('general.navbar.accountSettings')}
        </SC.MenuItem>
        <SC.MenuItem
          onClick={(...args: unknown[]) => {
            onMenuItemClick(...args);
            onLogout();
          }}
        >
          {t('general.navbar.logout')}
        </SC.MenuItem>
      </SC.Container>
    </SC.Backdrop>
  );
}
