export enum ProjectStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export interface ProjectUiInterface {
  id: string;
  name: string;
  createdAt: Date;
  status: ProjectStatus;
  requestCount: number;
  apiKey: string;
}

export interface ProjectStatUiInterface {
  id: string;
  count: number;
  projectName: string;
  hourTimestamp: Date;
}

export interface GetProjectsResponseData {
  api_key: string;
  created: string;
  id: number;
  name: string;
  requests_24h: number;
  status: boolean;
}

export interface AddProjectRequestData {
  name: string;
}

export interface RenameProjectRequestData {
  id: number;
  name: string;
}

export interface DeleteProjectRequestData {
  id: number;
}

export enum GetProjectStatisticsRequestFilterOptions {
  '24H' = '24h',
  '7Days' = '7days',
  '30Days' = '30days',
}

export interface GetProjectStatisticsRequestData {
  id: number;
  filter?: GetProjectStatisticsRequestFilterOptions;
}

export interface GetProjectStatisticsResponseData {
  id: number;
  count: string;
  name: string;
  timestamp: string;
}
