import styled from 'styled-components';

import Select from 'components/dumb/atomics/Select';

export const Container = styled.div`
  padding: 64px 0;

  @media only screen and (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const StyledSelect = styled(Select)`
  max-width: 320px;
  width: 100%;

  & + & {
    margin-left: 32px;
  }

  @media only screen and (max-width: 768px) {
    max-width: none;

    & + & {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

export const ChartWrapper = styled.section`
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  border-radius: 8px;
  padding: 24px;
  max-width: 100%;
  width: 100%;
`;
