import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  GetProjectStatisticsRequestFilterOptions,
  ProjectStatUiInterface,
  ProjectUiInterface,
} from 'api/requestWrappers/types/project';
import Chart, { ChartColor, ChartUnit } from 'components/dumb/Chart';

import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import * as SC from './styles';
import { statisticsPeriods } from '../../Statistics';

export default function ProjectStatistics() {
  const {
    project,
    projectStats,
    isDataLoading,
    onChangeActivePeriod,
  }: {
    project?: ProjectUiInterface;
    projectStats?: ProjectStatUiInterface[];
    isDataLoading: boolean;
    onChangeActivePeriod: (
      newActivePeriod: GetProjectStatisticsRequestFilterOptions
    ) => void;
  } = useOutletContext();
  const [activePeriod, setActivePeriod] = useState(statisticsPeriods[0]);

  const { t } = useTranslation();
  const theme = useTheme();

  const colors: ChartColor[] = useMemo(
    () => [
      {
        id: `id${Math.random()}`,
        stroke: theme.palette.primary[500],
        fill: theme.palette.primary[500],
      },
    ],
    []
  );

  return (
    <SC.ChartWrapper>
      <Chart
        title={t('project.statistics.chartTitle')}
        subtitle={String(project?.requestCount)}
        isLoading={isDataLoading}
        colors={colors}
        select={
          <SC.StyledSelect
            onSelect={(option) => {
              const enumValue =
                option.value as GetProjectStatisticsRequestFilterOptions;
              onChangeActivePeriod(enumValue);
              setActivePeriod({
                value: enumValue,
                label: option.label,
              });
            }}
            options={statisticsPeriods}
            activeOption={activePeriod}
          />
        }
        data={{
          meta: {
            value: {
              color: colors[0],
              unit: ChartUnit.REQUESTS,
            },
          },
          data:
            projectStats?.map((projectStat) => ({
              timestamp: projectStat.hourTimestamp.getTime(),
              value: projectStat.count,
            })) || [],
        }}
      />
    </SC.ChartWrapper>
  );
}
