import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosError } from 'axios';
import AbstractRequest from '../../api/requestWrappers/AbstractRequest';

const axiosBaseQuery =
  <RequestWrapperClass extends AbstractRequest>(
    requestWrapper: RequestWrapperClass
  ): BaseQueryFn<{
    classMethod: keyof RequestWrapperClass;
    classMethodParameters?: unknown[];
  }> =>
  async ({ classMethod, classMethodParameters }) => {
    try {
      // as any workaround to disable ts error for not allowing class property call
      const data = await (requestWrapper as any)[classMethod](
        ...(classMethodParameters || [])
      );
      return { data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
