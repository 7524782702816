import styled from 'styled-components';

import Select from 'components/dumb/atomics/Select';

export const ChartWrapper = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  border-radius: 8px;
  padding: 24px;
`;

export const StyledSelect = styled(Select)`
  input,
  input:active,
  input:focus {
    border: 0;
    width: 160px;
    outline: 0;
    font-weight: 600;
  }

  svg {
    top: 16px;
  }
`;
