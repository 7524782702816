import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PATH from 'routes/paths';
import FilinfoLogoAlt24px from 'components/dumb/icons/FilinfoLogoAlt24px';
import FilinfoLogo24px from 'components/dumb/icons/FilinfoLogo24px';
import { Link } from 'components/dumb/atomics/Button';
import { ButtonColor } from 'components/dumb/atomics/Button/constant';

import { NavLink, useMatch } from 'react-router-dom';
import * as SC from './styles';
import bgImage from './AsideBg.svg';

function AuthLayout({ children }: { children: ReactElement }) {
  const { t } = useTranslation();
  const isSignUpPage = useMatch(PATH.AUTH.SIGN_UP);

  return (
    <SC.Container>
      <SC.Aside style={{ backgroundImage: `url(${bgImage})` }}>
        <SC.AsideHeader>
          <SC.LogoLink to={PATH.INDEX}>
            <FilinfoLogoAlt24px />
            <h1>Filinfo</h1>
          </SC.LogoLink>
        </SC.AsideHeader>

        <SC.AsideBlock>
          <SC.AsideTitle>{t('auth.sidebar.title')}</SC.AsideTitle>
          <SC.AsideParagraph>
            <Trans
              i18nKey="auth.sidebar.paragraph"
              components={{
                docsLink: <NavLink to={PATH.DOCS} />,
              }}
            />
          </SC.AsideParagraph>
        </SC.AsideBlock>
      </SC.Aside>
      <SC.Main>
        <SC.MainHeader>
          <SC.MainHeaderLink to={PATH.DOCS}>
            {t('general.navbar.documentation')}
          </SC.MainHeaderLink>
          {isSignUpPage ? (
            <Link to={PATH.AUTH.LOGIN} color={ButtonColor.SECONDARY}>
              {t('general.navbar.login')}
            </Link>
          ) : (
            <Link to={PATH.AUTH.SIGN_UP} color={ButtonColor.SECONDARY}>
              {t('general.navbar.signup')}
            </Link>
          )}
        </SC.MainHeader>
        <SC.MobileMainHeader>
          <SC.MobileLogoLink to={PATH.INDEX}>
            <FilinfoLogo24px />
            <h1>Filinfo</h1>
          </SC.MobileLogoLink>

          <SC.MobileNav>
            <SC.MobileNavLink to={PATH.DOCS}>
              {t('general.navbar.docs')}
            </SC.MobileNavLink>
            {isSignUpPage ? (
              <SC.MobileNavLink to={PATH.AUTH.LOGIN} $primary>
                {t('general.navbar.login')}
              </SC.MobileNavLink>
            ) : (
              <SC.MobileNavLink to={PATH.AUTH.SIGN_UP} $primary>
                {t('general.navbar.signup')}
              </SC.MobileNavLink>
            )}
          </SC.MobileNav>
        </SC.MobileMainHeader>
        {children}

        <SC.Copyright>powered by DigitalMOB</SC.Copyright>
      </SC.Main>
    </SC.Container>
  );
}

export default AuthLayout;
