import { Trans } from 'react-i18next';
import { ReactElement } from 'react';

import { ProjectUiInterface } from 'api/requestWrappers/types/project';

// eslint-disable-next-line import/prefer-default-export
export function getProjectStatusText(
  status?: ProjectUiInterface['status']
): string | ReactElement {
  switch (status) {
    case 'active':
      return <Trans i18nKey="project.status.active" />;
    case 'inactive':
      return <Trans i18nKey="project.status.inactive" />;
    default:
      return <Trans i18nKey="project.status.na" />;
  }
}
