import { Route } from './types';
import PATH from './paths';
import ConnectedDashboardPage from '../components/smart/layouts/LoggedInLayout/ConnectedDashboardPage';

// eslint-disable-next-line import/no-mutable-exports
let routes: Route[] = [];
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const routeFiles = require.context(
  './',
  true,
  /^(?!.\/index|.\/paths|.\/patterns|.\/types|routes).*\.ts$/
);

routeFiles.keys().forEach((routeFile: string) => {
  routes = [...routes, ...routeFiles(routeFile).default];
});

const DASHBOARD: Route = {
  path: PATH.INDEX,
  component: ConnectedDashboardPage,
  isProtected: true,
};

export default [...routes, DASHBOARD];
