import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ProjectUiInterface } from 'api/requestWrappers/types/project';

export const Container = styled(NavLink)`
  padding: 24px;
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary[500]};
    box-shadow: 0 16px 32px rgba(93, 85, 250, 0.08);
  }

  > :first-child {
    flex: 0.64;
  }

  > :nth-child(2) {
    flex: 0.138;
  }

  > :last-child {
    flex: 0.222;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;

    > :nth-child(2) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    > :last-child {
      width: 100%;
      > * {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;

export const ProjectData = styled.div`
  display: flex;
  gap: 16px;
`;

export const ProjectAvatar = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(93, 85, 250, 0.08);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    margin: 0;
    ${({ theme }) => theme.typography.paragraph.p2};
    color: ${({ theme }) => theme.palette.primary[500]};
    font-weight: 700;
  }
`;

export const ProjectText = styled.div`
  > p {
    margin: 0;
    color: ${({ theme }) => theme.palette.neutrals[900]};
    ${({ theme }) => theme.typography.paragraph.p2};
    font-weight: 600;
  }
  > div {
    color: ${({ theme }) => theme.palette.neutrals[500]};
    ${({ theme }) => theme.typography.caption.c1};
  }
`;

export const ProjectStatus = styled.div<{
  status: ProjectUiInterface['status'];
}>`
  display: flex;
  flex-direction: column;
  margin-right: 32px;

  > p {
    margin: 0;
    color: ${({ theme, status }) => {
      switch (status) {
        case 'active':
          return theme.palette.supporting.success;
        case 'inactive':
          return theme.palette.supporting.warning;
        default:
          return theme.palette.neutrals[600];
      }
    }};
    ${({ theme }) => theme.typography.paragraph.p3};
  }

  > div {
    color: ${({ theme }) => theme.palette.neutrals[500]};
    ${({ theme }) => theme.typography.caption.c1};
  }
`;

export const ProjectRequestCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ProjectRequestCount = styled.div`
  display: inline-flex;
  flex-direction: column;

  > p {
    margin: 0;
    color: ${({ theme }) => theme.palette.neutrals[900]};
    ${({ theme }) => theme.typography.paragraph.p3};
    font-weight: 600;
    text-align: end;
  }

  > div {
    color: ${({ theme }) => theme.palette.neutrals[500]};
    ${({ theme }) => theme.typography.caption.c1};
  }
`;
