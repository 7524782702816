import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const DocumentationPage = lazy(
  () => import('components/dumb/layouts/AuthLayout/DocumentationLayout')
);

const DOCS: Route = {
  component: DocumentationPage,
  path: PATH.DOCS,
  isProtected: false,
};

export default [DOCS];
