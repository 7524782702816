export default {
  general: {
    navbar: {
      dashboard: 'Dashboard',
      statistics: 'Statistics',
      accountSettings: 'Account settings',
      logout: 'Log Out',
      login: 'Log In',
      signup: 'Sign Up',
      documentation: 'Documentation',
      docs: 'Docs',
    },
    select: {
      noOptions: 'No options',
    },
  },
};
