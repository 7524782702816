import styled from 'styled-components';

export const Container = styled.div`
  height: inherit;
  width: inherit;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 80px auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.supporting.white};
`;

export const Copyright = styled.div`
  margin-top: auto;
  color: ${({ theme }) => theme.palette.neutrals[300]};
  text-align: center;
  padding: 24px 24px 32px;

  @media only screen and (max-width: 768px) {
    padding: 24px;
  }
`;
