import { useTranslation } from 'react-i18next';
import * as SC from './styles';
import NoProjectIcon80px from '../../../../../icons/NoProjectIcon80px';

export default function NoProjectCard({
  onCreateNewProject,
}: {
  onCreateNewProject: CallbackFunction;
}) {
  const { t } = useTranslation();
  return (
    <SC.Container>
      <div>
        <NoProjectIcon80px />
      </div>
      <SC.Header>{t('dashboard.noProjectCard.title')}</SC.Header>
      <SC.Paragraph>{t('dashboard.noProjectCard.description')}</SC.Paragraph>
      <SC.Button onClick={onCreateNewProject}>
        {t('dashboard.createNewProject')}
      </SC.Button>
    </SC.Container>
  );
}
