import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Aside = styled.aside`
  flex: 1 1 50%;
  padding: 32px 80px;
  width: 50%;
  background-color: ${({ theme }) => theme.palette.primary[500]};
  background-size: cover;
  background-position: center 88px;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1024px) {
    padding: 32px;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const AsideHeader = styled.header`
  padding: 8px 0;
`;

export const LogoLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  > svg {
    margin-right: 12px;
  }

  > h1 {
    margin: 0;
    ${({ theme }) => theme.typography.paragraph.p2}
    font-weight: 600;
    color: ${({ theme }) => theme.palette.supporting.flatWhite};
  }
`;

export const AsideBlock = styled.div`
  margin: 120px auto 48px;
  max-width: 500px;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    margin: 64px auto 48px;
  }
`;

export const AsideTitle = styled.h2`
  ${({ theme }) => theme.typography.header.h1};
  margin: 0;
  color: ${({ theme }) => theme.palette.supporting.flatWhite};
  position: relative;

  @media only screen and (max-width: 1024px) {
    font-size: 48px;
  }
`;

export const AsideParagraph = styled.p`
  ${({ theme }) => theme.typography.paragraph.p1}
  color: ${({ theme }) => theme.palette.supporting.flatWhite};

  a {
    color: inherit;
  }
`;

export const Main = styled.main`
  color: ${({ theme }) => theme.palette.supporting.flatWhite};
  flex: 1 1 50%;
  padding: 32px 80px;
  width: 50%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    padding: 32px;
  }

  @media only screen and (max-width: 768px) {
    flex: auto;
    padding: 24px;
  }
`;

export const MainHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileMainHeader = styled.header`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const MobileLogoLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  > svg {
    margin-right: 12px;
    flex-shrink: 0;
  }

  > h1 {
    margin: 0;
    ${({ theme }) => theme.typography.paragraph.p2}
    font-weight: 600;
    color: ${({ theme }) => theme.palette.neutrals[900]};
  }
`;

export const MobileNav = styled.nav`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const MobileNavLink = styled(NavLink)<{ $primary?: boolean }>`
  ${({ theme }) => theme.typography.paragraph.p3};
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme, $primary }) =>
    $primary ? theme.palette.primary[500] : theme.palette.neutrals[500]};

  &:hover {
    color: ${({ theme, $primary }) =>
      $primary ? theme.palette.primary[400] : theme.palette.neutrals[900]};
  }

  & + & {
    margin-left: 24px;
  }
`;

export const MainHeaderLink = styled(NavLink)`
  ${({ theme }) => theme.typography.paragraph.p3};
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.neutrals[500]};
  margin-right: 32px;

  &:hover {
    color: ${({ theme }) => theme.palette.neutrals[900]};
  }
`;

export const Copyright = styled.div`
  margin-top: auto;
  color: ${({ theme }) => theme.palette.neutrals[300]};
  text-align: center;
`;
