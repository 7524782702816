import styled from 'styled-components';
import ButtonWrapper from '../../../../../atomics/ButtonWrapper';

export const Backdrop = styled.div<{ isActive: boolean }>`
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 80px;
  background: rgba(16, 42, 67, 0.8);
  backdrop-filter: blur(16px);
  touch-action: pan-x pinch-zoom;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    display: flex;
    transition: all 0.2s ease;
    transform: translateX(${({ isActive }) => (isActive ? 0 : '-100%')});
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled(ButtonWrapper)`
  padding: 24px 0 24px 24px;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  color: ${({ theme }) => theme.palette.neutrals[500]};
  ${({ theme }) => theme.typography.paragraph.p3};

  :last-child {
    background: ${({ theme }) => theme.palette.supporting.white};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
