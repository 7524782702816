import { ButtonHTMLAttributes, ReactNode } from 'react';
import { NavLinkProps } from 'react-router-dom';

import { ButtonColor } from './constant';
import * as SC from './styles';
import BlackSpinner16px from '../../icons/BlackSpinner16px';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  color?: ButtonColor;
  isLoading?: boolean;
  disabled?: boolean;
}

export function Button({
  color = ButtonColor.PRIMARY,
  isLoading,
  disabled,
  children,
  ...rest
}: ButtonProps) {
  return (
    <SC.Button $color={color} disabled={disabled || isLoading} {...rest}>
      {children}
      {isLoading && <BlackSpinner16px />}
    </SC.Button>
  );
}

interface LinkProps extends NavLinkProps {
  color?: ButtonColor;
}

export function Link({ color = ButtonColor.PRIMARY, ...rest }: LinkProps) {
  return <SC.Link $color={color} {...rest} />;
}
