import { ReactNode, useEffect, useState } from 'react';
import * as SC from './styles';
import CloseIcon24px from '../../icons/CloseIcon24px';
import ButtonWrapper from '../ButtonWrapper';

export default function Modal({
  isOpen,
  isLoading,
  onClose,
  title,
  children,
}: {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: CallbackFunction;
  title: string;
  children: ReactNode;
}) {
  const [delayedOpen, setDelayedOpen] = useState(isOpen);

  useEffect(() => {
    setTimeout(() => {
      setDelayedOpen(isOpen);
    }, 150);
  }, [isOpen]);

  return (
    <SC.Backdrop
      isOpen={isOpen || delayedOpen}
      onClick={isLoading ? undefined : onClose}
    >
      <SC.Container
        isOpen={isOpen ? delayedOpen : isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        <SC.TitleContainer>
          <h3>{title}</h3>
          <ButtonWrapper
            disabled={isLoading}
            onClick={isLoading ? undefined : onClose}
          >
            <CloseIcon24px style={{ display: 'block' }} />
          </ButtonWrapper>
        </SC.TitleContainer>
        {children}
      </SC.Container>
    </SC.Backdrop>
  );
}
