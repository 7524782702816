import styled from 'styled-components';
import { Button as AtomicButton } from '../../../../../atomics/Button';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 400px;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 24px;
  }
`;

export const Header = styled.h3`
  margin: -16px 0 0 0;
  ${({ theme }) => theme.typography.header.h3};
`;

export const Paragraph = styled.p`
  margin: 0;
  ${({ theme }) => theme.typography.paragraph.p3};
  text-align: center;
`;

export const Button = styled(AtomicButton)`
  width: 100%;
`;
