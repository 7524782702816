// eslint-disable-next-line max-classes-per-file
import { AbstractUiAdapter } from 'simple-abstract-ui-data-adapter';
import {
  GetProjectsResponseData,
  ProjectStatus,
  ProjectUiInterface,
} from '../types/project';

class ProjectUiAdapter
  extends AbstractUiAdapter<GetProjectsResponseData>
  implements ProjectUiInterface
{
  apiKey: string;

  createdAt: Date;

  id: string;

  name: string;

  requestCount: number;

  status: ProjectStatus;

  protected applyAttributes(apiProject: GetProjectsResponseData): void {
    this.apiKey = apiProject.api_key;
    this.createdAt = new Date(apiProject.created);
    this.id = apiProject.id.toString();
    this.name = apiProject.name;
    this.requestCount = apiProject.requests_24h;
    this.status = apiProject.status
      ? ProjectStatus.Active
      : ProjectStatus.Inactive;
  }

  protected applyRuntimeValidations() {
    if (Number.isNaN(this.createdAt.getTime())) {
      throw new Error('Invalid date string received from api!');
    }
  }
}

export default class ProjectUiHelper {
  public static async getUiModel(
    apiProject: GetProjectsResponseData
  ): Promise<ProjectUiInterface> {
    return ProjectUiAdapter.create<GetProjectsResponseData, ProjectUiInterface>(
      apiProject
    );
  }
}
