import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';

const ConnectedAccountSettingsPage = lazy(
  () =>
    import(
      'components/smart/layouts/LoggedInLayout/ConnectedAccountSettingsPage'
    )
);

const ACCOUNT_SETTINGS: Route = {
  component: ConnectedAccountSettingsPage,
  path: PATH.ACCOUNT_SETTINGS,
  isProtected: true,
};

export default [ACCOUNT_SETTINGS];
