import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
`;

export const Section = styled.section`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border-radius: 8px;

  & + & {
    margin-top: 32px;
  }
`;

export const SectionTitle = styled.h3<{ redColor?: boolean }>`
  ${({ theme }) => theme.typography.paragraph.p2};
  margin: 0 0 24px;
  font-weight: 600;
  color: ${({ theme, redColor }) =>
    redColor ? theme.palette.supporting.error : theme.palette.neutrals[900]};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonsWrapper = styled.div<{ stretchElements?: boolean }>`
  display: flex;
  gap: 24px;

  &.stretchElements > * {
    flex-basis: 100%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const SectionParagraph = styled.p`
  ${({ theme }) => theme.typography.paragraph.p3};
  color: ${({ theme }) => theme.palette.neutrals[500]};
  font-weight: 500;
  margin: 0;
`;
