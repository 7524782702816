import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.typography.caption.c1};
  font-weight: 500;
  color: ${({ theme }) => theme.palette.neutrals[500]};
`;

export const Input = styled.input`
  ${({ theme }) => theme.typography.paragraph.p3};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.neutrals[100]};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  color: ${({ theme }) => theme.palette.neutrals[900]};
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.palette.neutrals[300]};
  }

  &.isEmpty {
    background: rgba(${({ theme }) => theme.palette.neutrals.RGB_500}, 0.04);
  }

  &:hover {
    background: ${({ theme }) => theme.palette.supporting.flatWhite};
  }

  &:active {
    border: 1px solid ${({ theme }) => theme.palette.primary[500]};
  }

  &:focus {
    outline: 4px solid rgba(93, 85, 250, 0.12);
    border: 1px solid ${({ theme }) => theme.palette.primary[500]};
  }

  &[disabled] {
    border: 1px solid ${({ theme }) => theme.palette.neutrals[100]};
    outline: 0;
    background: ${({ theme }) => theme.palette.neutrals[50]};
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.palette.supporting.error};
  }
`;

export const Error = styled.div`
  ${({ theme }) => theme.typography.caption.c1};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.supporting.error};
  margin-top: 4px;
`;
