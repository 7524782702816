import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ButtonWrapper from '../../../atomics/ButtonWrapper';
import MainContainer from '../../../MainContainer';

export const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutrals[50]};
  height: 100%;
`;

export const StyledMainContainer = styled(MainContainer)`
  height: 100%;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
`;

export const LogoLink = styled(Link)`
  margin-right: 48px;
  display: flex;
  align-items: center;
  gap: 12px;
  ${({ theme }) => theme.typography.paragraph.p2};
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.neutrals[900]};
`;

export const MobileMenuButton = styled(ButtonWrapper)`
  display: none;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const PortalContainer = styled.div`
  margin-left: auto;
`;
