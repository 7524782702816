import { useEffect, useReducer } from 'react';
import { ProjectUiInterface } from '../../../../../api/requestWrappers/types/project';
import Dashboard from '../../../../dumb/layouts/LoggedInLayout/Content/Dashboard';
import {
  useAddProjectMutation,
  useGetProjectsQuery,
} from '../../../../../redux/slices/project';

export default function ConnectedDashboardPage() {
  const { data, isLoading } = useGetProjectsQuery('dashboardGet');
  const [
    addProject,
    { isLoading: createProjectIsLoading, isSuccess: createProjectIsSuccessful },
  ] = useAddProjectMutation();
  const projects: ProjectUiInterface[] = isLoading ? [] : data;

  const [projectCreatedIndex, updateProjectCreatedIndex] = useReducer(
    (x) => x + 1,
    0
  );

  useEffect(() => {
    if (createProjectIsSuccessful) {
      updateProjectCreatedIndex();
    }
  }, [createProjectIsSuccessful]);

  return (
    <Dashboard
      isLoading={isLoading}
      projects={projects}
      createProjectIsLoading={createProjectIsLoading}
      onCreateProjectSubmit={(name) => {
        addProject({ name });
      }}
      projectCreatedIndex={projectCreatedIndex}
    />
  );
}
