import {
  Action,
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { AbstractUiAdapter } from 'simple-abstract-ui-data-adapter';
import authReducer from './slices/auth';
import { projectApi } from './slices/project';

const appReducer = combineReducers({
  auth: authReducer,
  [projectApi.reducerPath]: projectApi.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState, action: Action) => {
  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const isSerializable = (value: any) =>
  value instanceof AbstractUiAdapter || value instanceof Date || isPlain(value);

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(serializableMiddleware)
      .concat(projectApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export default store;

export type AppDispatch = typeof store.dispatch;
