import styled, { DefaultTheme, keyframes } from 'styled-components';

const animation = (theme: DefaultTheme) => keyframes`
  0% {
    background-color: ${theme.palette.neutrals[50]};
  }
  50% {
    background-color: ${theme.palette.neutrals[100]};
  }
  100% {
  background-color: ${theme.palette.neutrals[50]};
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div<{
  $width?: string | number;
  $height?: string | number;
  $borderRadius?: string | number;
}>`
  width: ${({ $width }) =>
    $width ? `${$width}${typeof $width === 'number' ? 'px' : ''}` : '100%'};
  height: ${({ $height }) =>
    $height ? `${$height}${typeof $height === 'number' ? 'px' : ''}` : '100%'};
  border-radius: ${({ $borderRadius }) =>
    $borderRadius
      ? `${$borderRadius}${typeof $borderRadius === 'number' ? 'px' : ''}`
      : '8px'};
  background-color: ${({ theme }) => theme.palette.neutrals[50]};
  animation: ${({ theme }) => animation(theme)} 2s ease-in-out infinite;
`;
