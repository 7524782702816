import { ReactNode } from 'react';
import * as SC from './styles';

interface Props {
  children: ReactNode;
  to: string;
}

export default function ToolbarMenuItem({ children, to }: Props) {
  return (
    <SC.NavLink
      to={to}
      className={({ isActive }) => (isActive ? 'active' : '')}
    >
      {children}
    </SC.NavLink>
  );
}
