import { ReactNode, useContext } from 'react';
import Portal from '../../../../atomics/Portal';
import { ToolbarContext } from '../../../../../../contexts/ToolbarProvider';

export default function ToolbarPortal({ children }: { children: ReactNode }) {
  const { toolbarPortalNode } = useContext(ToolbarContext);
  return toolbarPortalNode ? (
    <Portal container={toolbarPortalNode}>{children}</Portal>
  ) : null;
}
