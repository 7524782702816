import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Children = styled.button`
  display: inline-flex;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
`;

export const ItemsWrapper = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutrals[100]};
  box-shadow: 0 16px 32px
    rgba(${({ theme }) => theme.palette.neutrals.RGB_900}, 0.08);
  border-radius: 8px;
  padding: 4px;
`;

export const Item = styled.button`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  ${({ theme }) => theme.typography.paragraph.p3};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.neutrals[500]};
  padding: 12px 20px;
  background: ${({ theme }) => theme.palette.supporting.flatWhite};
  border-radius: 6px;
  border: 0;
  white-space: nowrap;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.supporting.white};
  }

  &:active {
    background: ${({ theme }) => theme.palette.neutrals[50]};
  }
`;
