import axios, {
  AxiosInterceptorManager,
  AxiosRequestConfig,
  Method,
} from 'axios';
import TypeChecker from 'helpers/classes/TypeChecker';
import eventEmitter, { EVENTS } from 'eventEmitter';
import {
  FILINFO_API_HOST,
  FILINFO_API_PORT,
  FILINFO_API_PROTOCOL,
} from 'helpers/constants/envVars';
import { AUTH_ME_SUBROUTE, HttpStatusCode } from './constants';
import { QueryParam } from './types/abstract';

abstract class AbstractRequest {
  get apiProtocol(): string {
    return FILINFO_API_PROTOCOL;
  }

  get apiHost(): string {
    return FILINFO_API_HOST;
  }

  get apiPort(): string {
    return FILINFO_API_PORT;
  }

  get apiUrl(): string {
    return `${this.apiProtocol}://${this.apiHost}${
      this.apiPort ? `:${this.apiPort}` : ''
    }`;
  }

  abstract get apiResource(): string;

  get apiRoute(): string {
    return `${this.apiUrl}/${this.apiResource}`;
  }

  abstract get routes(): Record<string, string>;

  async request(
    method: Method,
    url: string,
    data?: unknown,
    extraConfig?: AxiosRequestConfig
  ) {
    let result;
    try {
      result = await axios({ method, url, data, ...extraConfig });
    } catch (e: any) {
      const responseUrl = e?.request?.responseURL;
      if (
        TypeChecker.isString(responseUrl) &&
        !responseUrl.endsWith(AUTH_ME_SUBROUTE) &&
        e?.response?.status === HttpStatusCode.UNAUTHORIZED
      ) {
        eventEmitter.emit(EVENTS.API.UNAUTHORIZED_ACCESS_REQUEST);
      }
      throw e;
    }
    return result;
  }

  getRequestInterceptors(): AxiosInterceptorManager<AxiosRequestConfig> {
    return axios.interceptors.request;
  }

  addRequestInterceptor(handler: (config: AxiosRequestConfig) => void): number {
    return this.getRequestInterceptors().use(handler);
  }

  removeRequestInterceptor(id: number): void {
    this.getRequestInterceptors().eject(id);
  }

  buildQueryParams(queryParams: QueryParam[]): string {
    let queryString = '';
    queryParams.forEach((queryParam) => {
      if (queryString) {
        queryString += `&${queryParam.key}=${queryParam.value}`;
      } else {
        queryString = `?${queryParam.key}=${queryParam.value}`;
      }
    });

    return queryString;
  }
}

export default AbstractRequest;
