import styled from 'styled-components';

import CopyIcon24px from 'components/dumb/icons/CopyIcon24px';

export const Button = styled.button`
  ${({ theme }) => theme.typography.paragraph.p3};
  color: ${({ theme }) => theme.palette.neutrals[500]};
  font-weight: 500;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  word-break: break-all;
`;

export const CopyIcon = styled(CopyIcon24px)<{ $copied?: boolean }>`
  margin-left: 8px;
  flex-shrink: 0;

  color: ${({ theme, $copied }) => {
    if ($copied) {
      return theme.palette.supporting.success;
    }
    if ($copied === false) {
      return theme.palette.supporting.error;
    }

    return theme.palette.primary[500];
  }};
`;
