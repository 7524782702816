import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const EVENTS = {
  API: {
    UNAUTHORIZED_ACCESS_REQUEST: 'api/unauthorizedAccessRequest',
  },
};

export default eventEmitter;
