export default {
  project: {
    createdDate: `Created {{date}}`,
    tabs: {
      statistics: 'Statistics',
      settings: 'Settings',
    },
    statistics: {
      chartTitle: 'Requests volume',
    },
    settings: {
      projectSection: {
        title: 'Project name',
        nameInput: {
          placeholder: 'Project name',
        },
        submitButton: 'Save changes',
      },
      endpointSection: {
        title: 'Endpoint',
      },
      deleteSection: {
        title: 'Delete project',
        paragraph: `By deleting the project, the API will stop returning data and you will lose access to any statistics data.`,
        submitButton: 'Delete project',
        modal: {
          title: 'Delete project',
          paragraph: `By deleting the project, the API will stop returning data and you will lose access to any statistics data.`,
          cancelButton: 'Cancel',
          submitButton: 'Delete project',
        },
      },
    },
    status: {
      active: 'Active',
      inactive: 'Inactive',
      na: 'N/A',
    },
  },
};
