import { createSelector } from '@reduxjs/toolkit';
import { AuthState } from './auth';
import { ProjectUiInterface } from '../../api/requestWrappers/types/project';
import { RootState } from '../store';
import { projectApi } from './project';

export const isAuthenticatedSelector = ({ auth }: { auth: AuthState }) =>
  auth.isAuthenticated;

export const isLoginLoadingSelector = ({ auth }: { auth: AuthState }) =>
  auth.isLoginLoading;

export const userSelector = ({ auth }: { auth: AuthState }) => auth.user;

export const selectProjectsResult =
  projectApi.endpoints.getProjects.select(undefined);

const emptyProjects: ProjectUiInterface[] = [];

export const selectAllProjects = createSelector(
  selectProjectsResult,
  (projectsResult) => projectsResult?.data ?? emptyProjects
);

export const selectProjectById = createSelector(
  selectAllProjects,
  (state: RootState, projectId: string) => projectId,
  (projects, projectId) => projects.find((project) => project.id === projectId)
);

/**
 * usage:
 * const project = useAppSelector((state) => selectProjectById(state, id));
 */
