import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectUiInterface } from 'api/requestWrappers/types/project';
import Input from 'components/dumb/atomics/Input';
import { Button } from 'components/dumb/atomics/Button';
import { ButtonColor } from 'components/dumb/atomics/Button/constant';
import CopyToClipboard from 'components/dumb/atomics/CopyToClipboard';
import Modal from 'components/dumb/atomics/Modal';

import * as SC from './styles';
import PATH from '../../../../../../../routes/paths';
import {
  useDeleteProjectMutation,
  useRenameProjectMutation,
} from '../../../../../../../redux/slices/project';

export default function ProjectSettings() {
  const navigate = useNavigate();
  const { project }: { project: ProjectUiInterface } = useOutletContext();
  const [renameProject, { isLoading: renameProjectIsLoading }] =
    useRenameProjectMutation();
  const [deleteProject, { isLoading: deleteProjectIsLoading }] =
    useDeleteProjectMutation();

  const [projectName, setProjectName] = useState('');
  const [deleteProjectModalIsOpen, setDeleteProjectModalIsOpen] =
    useState(false);

  useEffect(() => {
    if (project?.name) {
      setProjectName(project?.name);
    }
  }, [project]);

  const { t } = useTranslation();

  if (!project) {
    return <div>no data</div>;
  }

  const handlerSubmitProjectName = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    renameProject({ id: +project.id, name: projectName });
  };

  const handlerSubmitDeleteProject = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDeleteProjectModalIsOpen(true);
  };

  const handlerDeleteProject = () => {
    deleteProject({ id: +project.id })
      .unwrap()
      .then(() => {
        navigate(PATH.INDEX, { replace: true });
      });
  };

  return (
    <>
      <SC.Container>
        <SC.Section>
          <SC.SectionTitle>
            {t('project.settings.projectSection.title')}
          </SC.SectionTitle>
          <SC.Form onSubmit={handlerSubmitProjectName}>
            <Input
              type="text"
              placeholder={t(
                'project.settings.projectSection.nameInput.placeholder'
              )}
              required
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              disabled={renameProjectIsLoading}
            />
            <SC.ButtonsWrapper>
              <Button isLoading={renameProjectIsLoading}>
                {t('project.settings.projectSection.submitButton')}
              </Button>
            </SC.ButtonsWrapper>
          </SC.Form>
        </SC.Section>

        <SC.Section>
          <SC.SectionTitle>
            {t('project.settings.endpointSection.title')}
          </SC.SectionTitle>
          <CopyToClipboard text={project.apiKey}>
            {project.apiKey}
          </CopyToClipboard>
        </SC.Section>

        <SC.Section>
          <SC.SectionTitle redColor>
            {t('project.settings.deleteSection.title')}
          </SC.SectionTitle>
          <SC.Form onSubmit={handlerSubmitDeleteProject}>
            <SC.SectionParagraph>
              {t('project.settings.deleteSection.paragraph')}
            </SC.SectionParagraph>
            <SC.ButtonsWrapper>
              <Button color={ButtonColor.DANGER_ALT}>
                {t('project.settings.deleteSection.submitButton')}
              </Button>
            </SC.ButtonsWrapper>
          </SC.Form>
        </SC.Section>
      </SC.Container>

      <Modal
        isOpen={deleteProjectModalIsOpen}
        onClose={() => setDeleteProjectModalIsOpen(false)}
        title={t('project.settings.deleteSection.modal.title')}
        isLoading={deleteProjectIsLoading}
      >
        <SC.SectionParagraph>
          {t('project.settings.deleteSection.modal.paragraph')}
        </SC.SectionParagraph>
        <SC.ButtonsWrapper className="stretchElements">
          <Button
            type="button"
            color={ButtonColor.GHOST}
            onClick={() => setDeleteProjectModalIsOpen(false)}
            disabled={deleteProjectIsLoading}
          >
            {t('project.settings.deleteSection.modal.cancelButton')}
          </Button>
          <Button
            type="button"
            color={ButtonColor.DANGER}
            onClick={() => handlerDeleteProject()}
            isLoading={deleteProjectIsLoading}
          >
            {t('project.settings.deleteSection.modal.submitButton')}
          </Button>
        </SC.ButtonsWrapper>
      </Modal>
    </>
  );
}
