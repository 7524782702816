export default {
  accountSettings: {
    title: 'Account settings',
    emailSection: {
      title: 'E-mail',
      emailInput: {
        placeholder: 'E-mail',
      },
      submitButton: 'Save changes',
      cancelButton: 'Cancel',
    },
    passSection: {
      title: 'Password',
      currentPassInput: {
        label: 'Current password',
        placeholder: 'Password',
      },
      newPassInput: {
        label: 'New password',
        placeholder: 'Password',
      },
      newPass2Input: {
        label: 'Confirm password',
        placeholder: 'Password',
      },
      passwordMismatch: 'Password mismatch',
      submitButton: 'Save changes',
      cancelButton: 'Cancel',
    },
    deleteAccSection: {
      title: 'Delete account',
      paragraph:
        'By deleting the account, you will lose access to all your projects and the APIs will stop returning any data.',
      submitButton: 'Delete account',
      modal: {
        title: 'Delete account',
        paragraph:
          'By deleting the account, you will lose access to all your projects and the APIs will stop returning any data.',
        cancelButton: 'Cancel',
        submitButton: 'Delete account',
      },
    },
    signOut: 'Sign out',
  },
};
