import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import { ProjectUiInterface } from 'api/requestWrappers/types/project';
import NoProjectCard from './NoProjectCard';
import ProjectCard from './ProjectCard';
import ToolbarPortal from '../../Toolbar/ToolbarPortal';

import * as SC from './styles';
import CreateProjectModal from './CreateProjectModal';
import { OnCreateProjectSubmit } from './CreateProjectModal/types';

interface Props {
  projects: ProjectUiInterface[];
  createProjectIsLoading: boolean;
  onCreateProjectSubmit: OnCreateProjectSubmit;
  projectCreatedIndex: number;
  isLoading: boolean;
}

export default function Dashboard({
  projects,
  createProjectIsLoading,
  onCreateProjectSubmit,
  projectCreatedIndex,
  isLoading,
}: Props) {
  const hasProjects = Boolean(projects.length);
  const [createProjectModalIsOpen, setCreateProjectModalIsOpen] =
    useState(false);
  const { t } = useTranslation();

  const openCreateProjectModal = () => setCreateProjectModalIsOpen(true);

  useEffect(() => {
    if (projectCreatedIndex !== 0) {
      setCreateProjectModalIsOpen(false);
    }
  }, [projectCreatedIndex]);

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <ProjectCard isLoading />
          <ProjectCard isLoading />
        </>
      );
    }

    if (projects.length) {
      return projects.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ));
    }

    return <NoProjectCard onCreateNewProject={openCreateProjectModal} />;
  }, [isLoading, projects]);

  return (
    <SC.StyledMainContainer>
      {hasProjects && (
        <ToolbarPortal>
          <SC.DesktopCreateProjectButton onClick={openCreateProjectModal}>
            {t('dashboard.createNewProject')}
          </SC.DesktopCreateProjectButton>
          <SC.MobileCreateProjectButton onClick={openCreateProjectModal}>
            {t('dashboard.createProject')}
          </SC.MobileCreateProjectButton>
        </ToolbarPortal>
      )}
      <SC.Container hasProjects={hasProjects}>
        <SC.Title hasProjects={hasProjects}>
          {t('general.navbar.dashboard')}
        </SC.Title>

        {content}
      </SC.Container>
      <CreateProjectModal
        isOpen={createProjectModalIsOpen}
        isLoading={createProjectIsLoading}
        onSubmit={onCreateProjectSubmit}
        onClose={() => setCreateProjectModalIsOpen(false)}
      />
    </SC.StyledMainContainer>
  );
}
