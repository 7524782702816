import { idPattern } from '../patterns';

const PATH = Object.freeze({
  INDEX: '/',
  DOCS: '/docs',
  AUTH: {
    LOGIN: '/login',
    SIGN_UP: `/sign-up`,
    RESET_PASSWORD: `/reset-password`,
    CHANGE_PASSWORD: `/change-password`,
    EMAIL_CONFIRMED: `/confirm-email`,
  },
  PROJECT: {
    INDEX: '/project',
    CHILDREN: {
      ONE: `/project/${idPattern}`,
      SETTINGS: `/project/${idPattern}/settings`,
    },
  },
  STATISTICS: '/statistics',
  ACCOUNT_SETTINGS: '/account-settings',
});

export default PATH;
