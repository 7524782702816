import { lazy } from 'react';
import PATH from './paths';
import { Route } from './types';
import ProjectStatistics from '../components/dumb/layouts/LoggedInLayout/Content/ProjectPage/ProjectStatistics';
import ProjectSettings from '../components/dumb/layouts/LoggedInLayout/Content/ProjectPage/ProjectSettings';

const ConnectedProjectPage = lazy(
  () => import('components/smart/layouts/LoggedInLayout/ConnectedProjectPage')
);

const PROJECT: Route = {
  component: ConnectedProjectPage,
  path: PATH.PROJECT.INDEX,
  isProtected: true,
  children: [
    {
      component: ProjectStatistics,
      path: PATH.PROJECT.CHILDREN.ONE,
    },
    {
      component: ProjectSettings,
      path: PATH.PROJECT.CHILDREN.SETTINGS,
    },
  ],
};

export default [PROJECT];
