import {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as SC from './styles';

type DropdownItem = {
  label: string;
  onClick: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void;
};

interface DropdownProps {
  items: DropdownItem[];
  children: ReactElement;
  className?: string;
}

function Dropdown({ items, children, className }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!wrapperRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', clickHandler);
      document.addEventListener('keyup', keyboardHandler);
    }

    return () => {
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keyup', keyboardHandler);
    };
  }, [isOpen]);

  return (
    <SC.Wrapper className={className} ref={wrapperRef}>
      <SC.Children
        type="button"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        {children}
      </SC.Children>
      <SC.ItemsWrapper $isOpen={isOpen}>
        {items.map((item, idx) => (
          <SC.Item
            // eslint-disable-next-line react/no-array-index-key
            key={String(idx)}
            type="button"
            onClick={(e) => {
              item.onClick(e);
              setIsOpen(false);
            }}
          >
            {item.label}
          </SC.Item>
        ))}
      </SC.ItemsWrapper>
    </SC.Wrapper>
  );
}

export default Dropdown;
