import { ReactNode } from 'react';
import * as SC from './styles';

interface Props {
  children: ReactNode;
  [key: string]: unknown;
}

export default function ButtonWrapper({ children, ...rest }: Props) {
  return <SC.Container {...rest}>{children}</SC.Container>;
}
