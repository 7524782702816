import * as SC from './styles';

interface SkeletonProps {
  width?: string | number;
  height?: string | number;
  borderRadius?: string | number;
  className?: string;
  [x: string]: any;
}

export default function Skeleton({
  width,
  height,
  borderRadius,
  className,
  ...rest
}: SkeletonProps) {
  return (
    <SC.Wrapper
      className={className}
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      {...rest}
    />
  );
}
