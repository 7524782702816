import { useEffect, useRef, useState } from 'react';

export const useSkipFirstRun = (
  callback: CallbackFunction,
  dependencies: unknown[]
) => {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useHasChanged = (value: any): boolean => {
  const prevValue = usePrevious(value);
  return prevValue !== value;
};

export const pollPromise = <T>(
  promise: () => Promise<T>,
  { retryInMs = 2000 }: { retryInMs: number }
): { promise: Promise<string>; cancel(): void } => {
  let canceled = false;

  return {
    promise: new Promise((resolve) => {
      const poll = async () => {
        if (canceled) {
          throw new Error('Promise canceled.');
        }

        let result;
        try {
          result = await promise();
        } catch (e) {
          console.error(e);
        }
        if (result !== undefined) {
          resolve('Promise accepted.');
        } else {
          setTimeout(poll, retryInMs);
        }
      };
      poll();
    }),
    cancel: () => {
      canceled = true;
    },
  };
};

export const useGetInitialValue = <T>(value: T): T | undefined => {
  const isSet = useRef(false);
  const [initialValue, setInitialValue] = useState<T>(undefined);

  useEffect(() => {
    if ([null, undefined].includes(value) || isSet.current) {
      return;
    }
    isSet.current = true;
    setInitialValue(value);
  }, [value]);

  return initialValue;
};
