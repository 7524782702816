const locale = 'en-US';

export default function displayNumber(
  value: number,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
) {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}
